<template>
  <div>
    <b-row>
      <b-col>
        <span class="sourceCl">Frage {{ Frage }} / {{ fragenanzahl }}</span>
        <b-card class="mt-1">
          <component v-bind:is="componentFile" :Frage="Frage"></component>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="6"
        ><b-button @click="prevPage" variant="outline-dark" v-if="Frage > 1"
          ><v-icon name="arrow-left" scale="1" /></b-button
      ></b-col>
      <b-col cols="6"
        ><b-button @click="nextPage" variant="outline-dark"
          ><v-icon name="arrow-right" scale="1" /></b-button
      ></b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Frage: this.$route.params.Frage,
    };
  },
  methods: {
    nextPage() {
      this.$store.commit("setTransition", "fade-in-right");
      const currentUser = this.$store.getters.getCurrentUser;
      let Frage = parseInt(this.Frage) + 1;
      if (Frage > process.env.VUE_APP_FragenAnzahl) {
        this.$router.replace({
          path: "/" + currentUser.MitarbeiterLink + "/Fotos",
        });
      } else {
        this.$router.replace({
          path: "/" + currentUser.MitarbeiterLink + "/Fragen/" + Frage,
        });
      }
      this.$store.commit("setRvKey", Math.random());
    },
    prevPage() {
      this.$store.commit("setTransition", "fade-in-left");
      const currentUser = this.$store.getters.getCurrentUser;
      let Frage = parseInt(this.Frage) - 1;
      if (Frage > 0) {
        this.$router.replace({
          path: "/" + currentUser.MitarbeiterLink + "/Fragen/" + Frage,
        });
        this.$store.commit("setRvKey", Math.random());
      }
    },
  },
  computed: {
    componentFile() {
      return () => import(`@/components/Fragen/Frage_${this.Frage}.vue`);
    },
    fragenanzahl() {
      return process.env.VUE_APP_FragenAnzahl;
    },
  },
};
</script>